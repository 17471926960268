import React from "react"

import Layout from "../layouts"

const Privacy = () => (
  <Layout title="Privacy">
    <div className="container is-widescreen">
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <h1 className="title has-text-primary has-text-centered">
            Privacy
          </h1>
        </div>
      </div>
    </div>
  </Layout>
)

export default Privacy
